<template>
  <div class="orgCompliance">
    <h1 class="primary font-bold">Compliance</h1>
    <h3 class="text-sm sm:text-base text-primary my-2">You are required to complete a compliance check for each of your clinics once a quarter.</h3>
    <div>
      <vs-tabs class="compliance-list" id="compliance-id">
        <vs-tab label="Up Next" id="compliance-up-next">
          <complianceComponent status="upNext" />
        </vs-tab>
        <!-- <vs-tab label="Overdue" id="compliance-overdue">
          <complianceComponent status="failed" />
        </vs-tab> -->
        <vs-tab label="In Review" id="compliance-in-review">
          <complianceComponent :status="['inReview', 'changeRequested']" />
        </vs-tab>
        <vs-tab label="Done" id="compliance-done">
          <complianceComponent :status="['completed', 'skipped', 'failed']" />
        </vs-tab>
        <vs-tab label="All" id="compliance-all">
          <complianceComponent />
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import complianceComponent from "../../components/org-owner/compliance/complianceList.vue";

export default {
  components: {
    complianceComponent,
  },
  data: () => ({}),
};
</script>

<style lang="scss">
  .orgCompliance {
    h1 {
      color: #555;
    }
  }
</style>
